import React, { useState } from 'react';
import logoIcon from "./DXC_Logo_purple.svg";
import logoutIcon from './userIcon.svg';

const Header = ({ onLogout }) => {
  const [isLogoutPopupVisible, setLogoutPopupVisible] = useState(false);

  const toggleLogoutPopup = () => {
    setLogoutPopupVisible((prevState) => !prevState);
  };

  return (
    <header
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#f0f0f0',
        borderBottom: '1px solid #ccc',
        position: 'relative',
      }}
    >
      <div>
        <img
          src={logoIcon}
          alt="DXC Logo"
          style={{ width: '100px', height: '50px', padding: '11px 5px' }}
        />
      </div>

      <div
        onClick={toggleLogoutPopup}
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
        }}
        title="Logout"
      >
        <img
          src={logoutIcon}
          alt="Logout"
          style={{ width: '25px', height: '30px', padding: '11px 16px' }}
        />
        {isLogoutPopupVisible && (
          <div
            style={{
              position: 'absolute',
              top: '60px',
              right: '10px',
              backgroundColor: '#fff',
              border: '1px solid #ccc',
              borderRadius: '5px',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
              width: '120px',
              zIndex: 1000,
            }}
          >
            <button
  onClick={onLogout}
  style={{
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'left',
    padding: '2px 4px',
  }}
>
  <p>
    userId: 
    <span style={{ paddingLeft: '3px' }}>
      {localStorage.getItem("userName")}
    </span>
  </p>
  <p style={{ color: '#5f249f', paddingLeft: '47px' }}>
    Sign Out
  </p>
</button>

          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
