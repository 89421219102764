import React, { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';


export const redirectToLogin = () => {
    localStorage.clear();
    sessionStorage.clear();
    sessionStorage.setItem('isLogin', 'yes');

    const params = [
      'identity_provider=CAS-6ft1ma',
      'response_type=CODE',
      'client_id=3m751nfhlg4cph831e23e8gsj4',
      'scope=openid',
      'redirect_uri=' + encodeURIComponent( window.location.origin + '/hal-browser/el-web'),
    ];
    window.location.href = 'https://ft1-ma.auth.ap-southeast-1.amazoncognito.com/oauth2/authorize?' + params.join('&');
  };

const LoginRedirect = () => {
  const [userId, setUserId] = useState('');
  const getAccessToken = async (authorizationCode) => {
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const urlToken = 'https://ft1-ma.auth.ap-southeast-1.amazoncognito.com/oauth2/token';
    const payload = new URLSearchParams();
    payload.append('grant_type', 'authorization_code');
    payload.append('client_id', '3m751nfhlg4cph831e23e8gsj4');
    payload.append('code', authorizationCode);
    payload.append('redirect_uri', window.location.origin + '/hal-browser/el-web');

    try {
      const response = await fetch(urlToken, {
        method: 'POST',
        headers: headers,
        body: payload,
      });

      const data = await response.json();
      if (data.error || data.errors) {
        console.error('Error fetching token:', data.error || data.errors);
        return null;
      }
      const { access_token, id_token } = data;
      sessionStorage.setItem('id_token', id_token);
      localStorage.setItem('access_token', access_token);

      return access_token;
    } catch (error) {
      console.error('Failed to fetch access token:', error);
      return null;
    }
  };

  const get_tokenInfo = () => {
    const token = sessionStorage.getItem('id_token');
    if (token) {
      const tokenInfo = jwtDecode(token);
      return tokenInfo;
    }
    return null;
  };

  useEffect(() => {
    if (sessionStorage.getItem('isLogin') !== 'yes') {
      redirectToLogin();
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (code) {
        getAccessToken(code).then((accessToken) => {
          if (accessToken) {
            const tokenInfo = get_tokenInfo();
            if (tokenInfo) {
              const fetchedUserId = tokenInfo.identities?.[0]?.userId || tokenInfo.email;
              setUserId(fetchedUserId);
              localStorage.setItem('userName', fetchedUserId);
            }
          }
        });
      }
    }
  }, []);

  return (
    <div>
    </div>
  );
};

export default LoginRedirect;
