import React, { useState, useEffect } from "react";
import HalUI from "@dxc-technology/halstack-browser";
import "./App.css";
import lifeIcon from "./Life.svg";
import pncIcon from "./PnC.svg";
import groupIcon from "./Group.svg";
import LoginRedirect from "./loginRedirection.js";
import Header from "./Header.js";

const App = () => {
  const [selectedCategory, setSelectedCategory] = useState("Life");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleLogout = () => {
      console.log("Logging out...");
      localStorage.clear();
      sessionStorage.clear();
      window.open('https://ft1-ma.auth.ap-southeast-1.amazoncognito.com/logout', '_self');
      // redirectToLogin();
    };
    const getheaders = () => {
      const YOUR_API_KEY ={
        Authorization:'Bearer ' + localStorage.getItem('access_token'),
        'x-api-key':'RDLCNVCXiha91471uVDL35rzxk4egsj4ap7V8fXV',
        language:'en',
        country:'SGP',
        createUser:'soeadmin',
        username:"soeadmin",
        businessdateenable:"Y",
        lob:'L',
        region:'SGP'
      }
      return YOUR_API_KEY
    }

  // URL's for all type of lob
  const lifeUrls = [
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/life/prospects", headers: getheaders() },
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/life/policy-servicing/policyservicingcases", headers: getheaders() },
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/life/quotation/v2/quotes", headers: getheaders() },
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/life/application/v2/applications", headers: getheaders() },
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/life/businesscases", headers: getheaders() },
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/life/uw/underwriter-service/underwritercases", headers: getheaders() },
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/life/requirement", headers: getheaders() },
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/life/claimsOffice/claims", headers: getheaders()},
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/life/policy-servicing", headers: getheaders() },
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/life/policy-service", headers: getheaders() },
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/life/userrole-service/users", headers: getheaders() },
  ];

  const pncUrls = [
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/pnc/prospects", headers: getheaders() },
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/pnc/quotes", headers: getheaders() },
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/pnc/payments", headers: getheaders() },
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/pnc/organizationclients", headers: getheaders() },
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/pnc/policies", headers: getheaders() },
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/pnc/claimpersona-service/claimPersonas", headers: getheaders() },
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/pnc/callcenter-service/clientInquiry", headers: getheaders() },
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/pnc/service-provider", headers: getheaders() },
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/pnc/appointments", headers: getheaders() }
  ];

  const groupUrls = [
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/group/proposal", headers: getheaders() },
    { url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/group/registration", headers: getheaders() },
	{ url: "https://api-ft1-lambda.dxc-assure-policy.com/integral-api/group/underwriting",  headers: getheaders() },
  ];

  const getUrls = () => {
    if (selectedCategory === "Life") return lifeUrls;
    if (selectedCategory === "PNC") return pncUrls;
    if (selectedCategory === "Group") return groupUrls;
    return [];
  };

  useEffect(() => {
    checkTokenExit();  
  }, []);

  let checkTokenExit=()=>{
  const token = sessionStorage.getItem("id_token");
   if(token!==null && token!==undefined&& token!==''){
    setIsLoggedIn(true);
   }else{
    setIsLoggedIn(false);
    setInterval(() => {
      checkTokenExit()
    }, 500);
   }

  }

  return ( 
    <div className="App">
      {!isLoggedIn && <LoginRedirect />}
      {isLoggedIn && <Header onLogout={handleLogout} />}
      { isLoggedIn &&
      <div style={{ display: "flex" }}>
        <div style={{ width: "150px", borderRight: "1px solid #ccc", padding: "39px 6px", backgroundColor: "#f5f5f5" }}>
          <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
            {[
              { name: "Life", icon: lifeIcon },
              { name: "PNC", icon: pncIcon },
              { name: "Group", icon: groupIcon },
            ].map((category) => (
              <li
                key={category.name}
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: "10px 0",
                  padding: "10px",
                  textAlign: "center",
                  border:
                    selectedCategory === category.name
                      ? "2px solid #5f249f"
                      : "1px solid transparent",
                  borderRadius: "5px",
                  backgroundColor:
                    selectedCategory === category.name ? "#5f249f" : "transparent",
                  fontWeight: selectedCategory === category.name ? "bold" : "normal",
                  color: selectedCategory === category.name ? "#FFFFFF" : "#000",
                  transition: "all 0.3s ease",
                }}
                onClick={() => {
                  console.log(`Selected Category: ${category.name}`);
                  setSelectedCategory(category.name);
                }}
              >
                <img
                  src={category.icon}
                  alt={category.name}
                  style={{ width: "20px", height: "20px", marginRight: "10px", filter: selectedCategory === category.name ? "brightness(0) invert(1)" : "none" }}
                />
                {category.name}
              </li>
            ))}
          </ul>
        </div>
        <div style={{ flex: 1, padding: "22px 37px" }}>
          {getUrls().map((item, index) => (
            <HalUI key={`${selectedCategory}-${index}`} url={item.url} headers={item.headers} />
          ))}
        </div>
      </div>}
      
    </div>
  );
};

export default App;
